import React from 'react'
//import Cookies from 'js-cookie'


const Games = ({ state}) => {



    return(

        <div align={'center'} style={{display:'inline'}}>
            <h1>Games</h1>
            {state.games.map((game) =>(
                <div className={'game'}  style={{border:'2px solid gray'}} key={game.gameId}>

                    <h2> {game.playerOne}(x) vs. {game.playerTwo}(o)</h2>

                    <h3><a href={process.env.PUBLIC_URL+'/games/'+game.gameId}>Link to just this game</a></h3>

                    <h4>
                        <button style={{cursor:'pointer'}} onClick = {
                            function(){
                            if (window.confirm('Are you sure?')){
                            deleteGame(game.gameId)
                            }}
                        }>Delete Game</button>
                    </h4>

                    <table>
                        <tbody>
                        <tr value = '0'>
                            <td align={'center'} style={{border:'2px solid gray',height:'50px',width:'50px'}}>
                                <span
                                    style={{textDecoration:'none',fontSize:'250%',color:'#3366CC',cursor:'pointer'}}
                                    onClick = {() => {
                                        sendMove(game.gameId, '0', '0');
                                        refreshPage();
                                    }}>
                                    {game.boardState[0][0]}
                                </span>
                            </td>
                            <td align={'center'} style={{border:'2px solid gray',height:'50px',width:'50px'}}>
                                <span
                                    style={{textDecoration:'none',fontSize:'250%',color:'#3366CC',cursor:'pointer'}}
                                    onClick = {() => {
                                            sendMove(game.gameId, '0', '1');
                                            refreshPage();
                                    }}>
                                    {game.boardState[0][1]}
                                </span>
                            </td>
                            <td align={'center'} style={{border:'2px solid gray',height:'50px',width:'50px'}}>
                                <span
                                    style={{textDecoration:'none',fontSize:'250%',color:'#3366CC',cursor:'pointer'}}
                                    onClick = {() => {
                                        sendMove(game.gameId, '0', '2');
                                        refreshPage();
                                    }}>
                                    {game.boardState[0][2]}
                                </span>
                            </td>
                        </tr>
                        <tr value = '1' >
                            <td align={'center'} style={{border:'2px solid gray',height:'50px',width:'50px'}}>
                                <span
                                    style={{textDecoration:'none',fontSize:'250%',color:'#3366CC',cursor:'pointer'}}
                                    onClick = {() => {
                                        sendMove(game.gameId, '1', '0');
                                        refreshPage();
                                    }}>
                                    {game.boardState[1][0]}
                                </span>
                            </td>
                            <td align={'center'} style={{border:'2px solid gray',height:'50px',width:'50px'}}>
                                <span
                                    style={{textDecoration:'none',fontSize:'250%',color:'#3366CC',cursor:'pointer'}}
                                    onClick = {() => {
                                        sendMove(game.gameId, '1', '1');
                                        refreshPage();
                                    }}>
                                    {game.boardState[1][1]}
                                </span>
                            </td>
                            <td align={'center'} style={{border:'2px solid gray',height:'50px',width:'50px'}}>
                                <span
                                    style={{textDecoration:'none',fontSize:'250%',color:'#3366CC',cursor:'pointer'}}
                                    onClick = {() => {
                                        sendMove(game.gameId, '1', '2');
                                        refreshPage();
                                    }}>
                                    {game.boardState[1][2]}
                                </span>
                            </td>
                        </tr>

                        <tr>
                            <td align={'center'} style={{border:'2px solid gray',height:'50px',width:'50px'}}>
                                <span
                                    style={{textDecoration:'none',fontSize:'250%',color:'#3366CC',cursor:'pointer'}}
                                    onClick = {() => {
                                        sendMove(game.gameId, '2', '0');
                                        refreshPage();
                                    }}>
                                    {game.boardState[2][0]}
                                </span>
                            </td>
                            <td align={'center'} style={{border:'2px solid gray',height:'50px',width:'50px'}}>
                                <span
                                    style={{textDecoration:'none',fontSize:'250%',color:'#3366CC',cursor:'pointer'}}
                                    onClick = {() => {
                                        sendMove(game.gameId, '2', '1');
                                        refreshPage();
                                    }}>
                                    {game.boardState[2][1]}
                                </span>
                            </td>
                            <td align={'center'} style={{border:'2px solid gray ',height:'50px',width:'50px'}}>
                                <span
                                    style={{textDecoration:'none',fontSize:'250%',color:'#3366CC',cursor:'pointer'}}
                                    onClick = {() => {
                                        sendMove(game.gameId, '2', '2');
                                        refreshPage();
                                    }}>
                                    {game.boardState[2][2]}
                                </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p><b>{game.currentPlayer}'s turn ({game.xturn}) </b></p>
                    <p>Message: {game.message}</p>
                    <br/><br/>
                </div>
            ))}
        </div>
    )
};



function sendMove(gameId, xLocation, yLocation){
    //var csrf = getCsrf();
    //console.log("VAR ="+csrf);
    //console.log("Type of ="+typeof csrf);

    fetch('https://ticapi.wdt8.net/game/move',{
        method:'POST',
        headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //'X-CSRF-TOKEN':document.cookie.split("=")[1]
        },
        body: JSON.stringify({
            'gameId': gameId.toString(),
            'x': xLocation,
            'y': yLocation,
        })
    }).then(function(response){
        return response.json();
    }).then(function(data){
        console.log('Took Move', data.html_url);

    });
}

function deleteGame(gameId){
    fetch('https://ticapi.wdt8.net/game/'+gameId,{
        method:'delete',
        headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            'gameId': gameId
        })
    }).then(function(response){
        goHome();
        return response.json();
    }).then(function(data){
        console.log('Deleted Game:', data.html_url);

    });
}

function refreshPage(){
    window.location.reload();
}

function goHome(){
    window.location.href=process.env.PUBLIC_URL;
}

/*
function getCsrf(){
    fetch('http://localhost:5000/csrf-token/')
        .then(res => res.json())
            //res => res.json().then((body) => console.log("BODY"+body.toString())))
        .then((data) => {
            //console.log("CSRF:"+data.CSRF);
            //console.log("CSRF HERERE:"+JSON.stringify(data));

            console.log("RETURNING:"+String(data.CSRF));
            console.log("TYPE OF:"+typeof data.CSRF);

            this.setState({currentCsrf:data.CSRF})
            return String(data.CSRF);
            //this.setState({games: data})
        })
        .catch(console.log)
}
*/

export default Games;
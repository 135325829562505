import React, {Component, Props} from 'react';
import Games from './components/games';
import { RouteProps } from 'react-router';
import {BrowserRouter, Route, Redirect} from "react-router-dom";


class App extends Component <Props<string> & RouteProps>{



    state={
        games:[],
        playerOne:String,
        playerTwo:String,
        currentCsrf:String,
        currentDomain:String
    }

    //const var apiAddress  = "https://ticapi.wdt8.net/";




  componentDidMount(){

        //this.setState({currentCsrf:this.getCsrf()});
console.log("Mounted");
      //this.getCsrf();
        //if (window.location.href.split('3000')[1].split('/').length === 3)
        if (!isNaN(parseInt(window.location.href.split('games/')[1])))
          fetch('https://ticapi.wdt8.net/game/'+window.location.href.split('games/')[1])
              .then( res => res.json())
              .then((data) => {
                  data.sort(sortMap)
                  this.setState({games: data})
              })
              .catch(console.log)
      else
        fetch('https://ticapi.wdt8.net/game/')
            .then( res => res.json())
            .then((data) => {
                try {
                    if (window.location.href.split("games/")[1] === 'old')
                        data.sort(sortMapOldest);
                    else
                        data.sort(sortMap);
                }catch(NullPointerException){
                    data.sort(sortMap);
                }
                //console.log("DATA2:"+data[0].gameId);
              this.setState({games: data})
            })
            .catch(console.log)

      }

  async createGame (playerOne: String, playerTwo: String){

        //this.getCsrf()

      //console.log(document.cookie.split("=")[1]);
        ///await this.getCsrf()


      //await this.getCsrf();

/*        console.log("FETCH:"+fetch('http://localhost:5000/csrf-token/').then((response) => {
            return response.json().then((data) => {
                console.log(data);
                return data.CSRF;
            }).catch((err) => {
                console.log(err);
            })
        }));

*/
//console.log(document.cookie.split("=")[1].toString());
        //this.getCsrf();
       //this.getCsrf();

       fetch('https://ticapi.wdt8.net/game',{
          method:'POST',
          headers:{
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-CSRF-Token':document.cookie.split("=")[1]
              //'X-CSRF-Token':document.cookie.split("=")[1]
          },
          body: JSON.stringify({
              'playerOne': playerOne,
              'playerTwo': playerTwo,
          })
      }).then(function(response){
          return response.json();
      }).then(function(data){
          console.log('Created Game:', data.html_url);
      });
       // console.log("Huh?");
     // console.log(document.cookie.split("=")[1]);
    }


    getOneGame = (gameId: String) => {
        fetch('https://ticapi.wdt8.net/game/'+gameId)
            .then( res => res.json())
            .then((data) => {
                return data;
                //this.setState({games: data})
            })
            .catch(console.log)
    }


    takeMove = (xLocation: String, yLocation: String, gameId: String) =>{
        fetch('https://ticapi.wdt8.net/game/move',{
            method:'post',
            headers:{
                'X-CSRF-TOKEN':'a',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache'
            },
            body: JSON.stringify({
            'gameId': gameId,
            'x': xLocation,
            'y': yLocation,
            })
        }).then(function(response){
            return response.json();
        }).then(function(data){
            console.log('Took Move', data.html_url);
        });

    }
/*
    getCsrf = () =>{
        fetch('http://localhost:5000/csrf-token/')
            .then( res => res.text())
            .then((data) => {
                return data;
                //this.setState({games: data})
            })
            .catch(console.log)
    }
*/
    async getCsrf(){
        await fetch('https://ticapi.wdt8.net/csrf-token/',{
            method:'GET'})
            .then(res => res.json())
            //res => res.json().then((body) => console.log("BODY"+body.toString())))
            .then((data) => {
                //console.log("CSRF:"+data.CSRF);
                //console.log("CSRF HERERE:"+JSON.stringify(data));

                //console.log("RETURNING:"+String(data.CSRF));
                //console.log("TYPE OF:"+typeof data.CSRF);
                this.setState({currentCsrf:data.CSRF})
                document.cookie = "XSRF-TOKEN="+data.CSRF;
                this.setState({currentDomain:window.location.hostname.toString()})
                return data;
                //this.setState({games: data})
            })
            .catch(console.log)
    }

    
goHome(){
    window.location.href=process.env.PUBLIC_URL;
}



  render () {

      /*<form onSubmit={() => this.createGame((document.getElementById('playerOne') as HTMLInputElement).value,(document.getElementById('playerTwo') as HTMLInputElement).value)+";return false"}>
      */
      /*                  </form>




      
            <Route exact path={process.env.PUBLIC_URL+"(/games|)(/|/new|)"}>
            <Route exact path={process.env.PUBLIC_URL+"/games/old"}>
            <Route exact path='/games/([0-9]+)' testId='test' id='test'>
*/



/*
OLDEST:


                    <h4><span onClick={function(){window.location.href=process.env.PUBLIC_URL+"/games/old"}}>
                        <button>Sort by Oldest Games</button>
                    </span></h4>

*/
    return (

        <BrowserRouter >


            <h4><a href="https://ticapi.wdt8.net/help">API Help</a></h4>



            <Route exact path="/">
                <Redirect to={process.env.PUBLIC_URL+"/games"}/>
            </Route>

            <Route exact path="(/games|)(/new|)">
                <div>

                    <h1> Tic-Tac-Toe</h1>
                    <h2> Make a game </h2>

                        <p> Player One Name: <input id={'playerOne'} type={'text'}/> </p>
                        <p> Player Two Name: <input id={'playerTwo'} type={'text'}/> </p>
                        <br/>

                        <span onClick={() => {this.goHome()}}>
                    <form onSubmit={() => this.createGame((document.getElementById('playerOne') as HTMLInputElement).value,(document.getElementById('playerTwo') as HTMLInputElement).value)+";return false"}>
                        <button style={{cursor:'pointer'}}>Submit</button>
                    </form></span>

                    <br/>

                    <h4><span onClick={function(){window.location.href=process.env.PUBLIC_URL+"/games/old"}}>
                        <button>Sort by Oldest Games</button>
                    </span></h4>


                    <Games state={this.state}></Games>

                    <div>
                    </div>
                </div>
            </Route>

            <Route exact path="/games/old">
                <div>

                    <h1> Tic-Tac-Toe</h1>
                    <h2> Make a game </h2>
                    <form onSubmit={() => this.createGame((document.getElementById('playerOne') as HTMLInputElement).value,(document.getElementById('playerTwo') as HTMLInputElement).value)}>

                        <p> Player One Name: <input id={'playerOne'} type={'text'}/> </p>
                        <p> Player Two Name: <input id={'playerTwo'} type={'text'}/> </p>
                        <br/>
                        <button style={{cursor:'pointer'}}>Submit</button>
                    </form>

                    <br/>


                    <h4><span onClick={function(){window.location.href=process.env.PUBLIC_URL+"/games"}}>
                        <button>Sort by Newest Games</button>
                    </span></h4>

                    <Games state={this.state}></Games>

                    <div>
                    </div>
                </div>
            </Route>

            <Route exact path='/games/([0-9]+)' testId='test' id='test'>
                <div>
                    <h3><a href={process.env.PUBLIC_URL+'/games/'}>Back Home</a></h3>
                </div>
                <Games state={this.state}></Games>

            </Route>

        </BrowserRouter>
    )
  }




}


function sortMap(a: {gameId: string} ,b: {gameId: string}){


    let x = parseInt(a.gameId);
    let y = parseInt(b.gameId);

    if (x<y)
        return 1
    else if (x>y)
        return -1
    return 0
}

function sortMapOldest(a: {gameId: string} ,b: {gameId: string}){


    let x = parseInt(a.gameId);
    let y = parseInt(b.gameId);

    if (x<y)
        return -1
    else if (x>y)
        return 1
    return 0
}


export default App;



/*


                    <h4 style={{display:'inline'}}>Sort by: <select style={{display:'inline'}}>
                        <option value='Newest'>Newest</option>
                        <option value='Oldest'onSelect={this.game.sort(sortMap)}>Oldest</option>
                    </select>
                    </h4>

 *//*
const testPage = () => {


    return(

        <body>
        <p> hello</p>
        <h2>hello </h2>

        </body>

    )


}
//{this.getOneGame(window.location.href.split('aa/')[1])}
//

//{this.setState({gameId:window.location.href.split('aa/')[1]})}
/*
render() {
    return (

        <Router>
            <Route exact path="/games"> 1Hi1 </Route>
            <Route exact path='/games/:id'> 2Hi2 </Route>
        </Router>

    )
}*/



// <button onClick="createGame()">Create Game</button>





    /*                        <br/>
     <br/>
     <br/>
     <h2 style={{color:'red'}}> DEPRECATED: Take a Move Manually </h2>
     <form onSubmit={() => this.takeMove((document.getElementById('xLoc') as HTMLInputElement).value,(document.getElementById('yLoc') as HTMLInputElement).value,(document.getElementById('gameId') as HTMLInputElement).value)}>
     <p> Game ID: <input id={'gameId'} type={'text'}/> </p>
     <p> X Location: <input id={'xLoc'} type={'text'}/> </p>
     <p> Y Location: <input id={'yLoc'} type={'text'}/> </p>

     <br/>
     <button>Submit</button>
     </form>
     */
    /*
<div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
*/

